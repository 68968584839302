import { Box, Flex, HStack, Image, Progress, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";

interface ContentDisplayBoxProps {
  post: object;
  onOpen: Function;
  onClick: Function;
  isOpen: Boolean;
  setSelectedPostImage: Function;
  isSelected: Boolean;
}

const ContentDisplayBox: React.FC<ContentDisplayBoxProps> = (props) => {
  return (
    <Box
      onClick={() => {
        props.setSelectedPostImage(props.post);
        props.onClick();
      }}
      borderWidth={props.isSelected ? "3px" : "0px"}
      borderColor={COLORS_PRIMARY.SELECTED_SPECIAL_BUTTON}
      width={{ base: "5.5rem", md: "10.2rem", lg: "13.7rem", xl: "18.2rem" }}
      height={{ base: "5.5rem", md: "10.2rem", lg: "13rem", xl: "18.2rem" }}
      backgroundColor={COLORS_PRIMARY.CONTENT_DISPLAY_BOX_BACKGROUND}
      justifySelf="center"
      zIndex="0"
      marginBottom={{ base: "0", md: "2rem" }}
    >
      <Image
        borderColor={COLORS_PRIMARY.BACKGROUND}
        w="100%"
        h="100%"
        src={props.post.asset_url}
      />
    </Box>
  );
};

export default ContentDisplayBox;
