import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import SelectionScreen from "../components/selection_screen/selection_screen";
import Header from "../components/header/header";

function LoadingPage() {
  return (
    <ChakraProvider>
      <Header />
      <SelectionScreen />
    </ChakraProvider>
  );
}

export default LoadingPage;
