import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Center,
  Stack,
  HStack,
  VStack,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import React from "react";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";
import ContentEditBox from "./content_edit_box";

import "./nft_edit_screen.css";

interface NFTEditDrawerProps {
  isOpen: boolean;
  onClose: Function;
  post: object;
  postId: string;
  setPostAddedToCollection: Function;
  postsToBeAddedToCollection: string[];
}

const NFTEditDrawer: React.FC<NFTEditDrawerProps> = (props) => {
  const [valueOne, setValueOne] = React.useState(false);
  const [valueTwo, setValueTwo] = React.useState(false);
  const [valueThree, setValueThree] = React.useState(false);
  const [valueFour, setValueFour] = React.useState(false);
  const [radioBackgroundOne, setRadioBackgroundOne] = React.useState(null);
  const [radioBackgroundTwo, setRadioBackgroundTwo] = React.useState(null);
  const [radioBackgroundThree, setRadioBackgroundThree] = React.useState(null);
  const [radioBackgroundFour, setRadioBackgroundFour] = React.useState(null);

  const renderDrawer = () => {
    const addPostIdWithoutDuplicates = (postId: string) => {
      if (!props.postsToBeAddedToCollection.includes(postId)) {
        props.setPostAddedToCollection([
          ...props.postsToBeAddedToCollection,
          postId,
        ]);
      } else {
        return;
      }
    };

    const unselectPost = (postId: string) => {
      if (props.postsToBeAddedToCollection.includes(postId)) {
        const tempFilteredPostIds = props.postsToBeAddedToCollection.filter(
          (existingPostId) => postId !== existingPostId
        );
        props.setPostAddedToCollection(tempFilteredPostIds);
      } else {
        return;
      }
    };

    const handleButtonRemoveFromCollection = () => {
      unselectPost(props.post.post_id);
      props.onClose();
    };

    const handleButtonAddToCollection = () => {
      addPostIdWithoutDuplicates(props.post.post_id);
      props.onClose();
    };

    return (
      <Box
        justifyContent="center"
        padding={{ base: "2rem", xl: "8rem" }}
        height="100%"
        width="100%"
        backgroundColor={COLORS_PRIMARY.BACKGROUND}
      >
        <OpaliteTextSubTitle marginBottom="1rem" fontWeight="bold">
          Edit {props.post.title}
        </OpaliteTextSubTitle>
        <Center>
          <ContentEditBox post={props.post} />
        </Center>
        {/* <OpaliteTextStandard marginTop="1rem" fontSize="1.25rem">
          What do you want to include:
        </OpaliteTextStandard> */}
        <Box marginTop="1rem" display="flex" flexDirection="row">
          {/* <VStack alignItems="start" width="50%">
            <RadioGroup
              onClick={() => {
                if (valueOne) {
                  setRadioBackgroundOne(null);
                } else {
                  setRadioBackgroundOne(COLORS_PRIMARY.SELECTED_SPECIAL_BUTTON);
                }
              }}
              onChange={() => setValueOne(!valueOne)}
              value={valueOne}
            >
              <Radio style={{ backgroundColor: radioBackgroundOne }} value="1">
                <OpaliteTextStandard>Caption</OpaliteTextStandard>
              </Radio>
            </RadioGroup>
            <RadioGroup
              onClick={() => {
                if (valueTwo) {
                  setRadioBackgroundTwo(null);
                } else {
                  setRadioBackgroundTwo(COLORS_PRIMARY.SELECTED_SPECIAL_BUTTON);
                }
              }}
              onChange={() => setValueTwo(!valueTwo)}
              value={valueTwo}
            >
              <Radio style={{ backgroundColor: radioBackgroundTwo }} value="2">
                <OpaliteTextStandard>Like Count</OpaliteTextStandard>
              </Radio>
            </RadioGroup>
          </VStack> */}
          {/* <VStack alignItems="start" width="50%">
            <RadioGroup
              onClick={() => {
                if (valueThree) {
                  setRadioBackgroundThree(null);
                } else {
                  setRadioBackgroundThree(
                    COLORS_PRIMARY.SELECTED_SPECIAL_BUTTON
                  );
                }
              }}
              onChange={() => setValueThree(!valueThree)}
              value={valueThree}
            >
              <Radio
                style={{ backgroundColor: radioBackgroundThree }}
                value="1"
              >
                <OpaliteTextStandard>Comment Count</OpaliteTextStandard>
              </Radio>
            </RadioGroup>
            <RadioGroup
              onClick={() => {
                if (valueFour) {
                  setRadioBackgroundFour(null);
                } else {
                  setRadioBackgroundFour(
                    COLORS_PRIMARY.SELECTED_SPECIAL_BUTTON
                  );
                }
              }}
              onChange={() => setValueFour(!valueFour)}
              value={valueFour}
            >
              <Radio style={{ backgroundColor: radioBackgroundFour }} value="1">
                <OpaliteTextStandard>Shares</OpaliteTextStandard>
              </Radio>
            </RadioGroup>
          </VStack> */}
        </Box>
        <Flex
          width={"100%"}
          paddingBottom={"1rem"}
          justifyItems="center"
          flexDirection="column"
        >
          {/* <HStack width="50%" marginTop="3rem">
            <label className="switch">
              <input type="checkbox"></input>
              <span className="slider round"></span>
            </label>
            <OpaliteTextStandard>Apply to all</OpaliteTextStandard>
          </HStack> */}

          <OpaliteButtonStandard
            onClick={() => {
              handleButtonAddToCollection();
            }}
            marginTop="3rem"
            width="100%"
          >
            Add to collection
          </OpaliteButtonStandard>
          <OpaliteButtonStandard
            onClick={() => {
              handleButtonRemoveFromCollection();
            }}
            marginTop="1.5rem"
            width="100%"
          >
            Removed from collection
          </OpaliteButtonStandard>
          <OpaliteButtonStandard
            onClick={() => {
              props.onClose();
            }}
            marginTop="1.5rem"
            width="100%"
          >
            Cancel
          </OpaliteButtonStandard>
        </Flex>
      </Box>
    );
  };
  return (
    <Drawer placement="bottom" onClose={props.onClose} isOpen={props.isOpen}>
      <DrawerOverlay>
        <DrawerContent
          paddingInline={{ base: "0", md: "30vw" }}
          backgroundColor={COLORS_PRIMARY.BACKGROUND}
          overflow="scroll"
          h="95vh"
          position="relative"
          alignItems="center"
          justifyContent={{ base: "auto", md: "center" }}
          width={{ base: "100v" }}
        >
          {renderDrawer()}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default NFTEditDrawer;
