import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Select,
  VStack,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import TempProfilePhoto from "../../images/temp_profile_picture.svg";
import {
  OpaliteTextHighlighted,
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";
import ContentDisplayBox from "./content_display_box";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import NFTEditDrawer from "./nft_edit_screen";
import { navigate } from "gatsby";
import { ApiContext, UserContext } from "../Root";

interface SelectionScreenProps {}

const SelectionScreen: React.FC<SelectionScreenProps> = (props) => {
  const api = useContext(ApiContext);
  const user = useContext(UserContext);
  const [renderedContentList, setRenderedContentList] = useState([]);
  const [postsToBeAddedToCollection, setPostsToBeAddedToCollection] = useState(
    []
  );
  const [selectedPost, setSelectedPost] = useState({
    postId: "",
    title: "",
    image: "",
    likes: "",
    comments: "",
    shares: "",
    caption: "",
  });
  const [content, setContent] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDrawer = () => {
    onOpen();
  };

  const [influencerId, setInfluencerId] = useState("");
  const [collectionName, setCollectionName] = useState("My Collection");
  const [openingPrice, setOpeningPrice] = useState("10");
  const [collectionId, setCollectionId] = useState("");

  const updateCollection = async () => {
    const data = {
      collection_id: collectionId,
      collection_name: collectionName,
      opening_price: openingPrice,
    };
    const res = await api.client.updateCollection(data);
    console.log("res", res);
  };

  const createNewCollection = async () => {
    const newCollectionRes = await api.client.createCollection({
      influencer_id: influencerId,
      collection_name: "My Collection",
      opening_price: 10,
    });
    setCollectionId(newCollectionRes.collection_id);
  };

  const selectionScreenUseEffect = async () => {
    const influencerRes = await api.client.getInfluencerByAccountId(
      user.data.account_id
    );
    setInfluencerId(influencerRes.influencer_id);
    const res = await api.client.getPostsByInfluencerId({
      influencer_id: influencerRes.influencer_id,
    });
    setContent(res);
    if (res) {
      createNewCollection();
    }
  };

  const checkIsSelected = (postId: string) => {
    let selectedState = false;
    postsToBeAddedToCollection.forEach((postIdInArray) => {
      if (postId === postIdInArray) {
        selectedState = true;
      }
    });
    return selectedState;
  };

  useEffect(() => {
    selectionScreenUseEffect();
    console.log("RENDERED CONTENT:", renderContent());
    setRenderedContentList(renderContent());
  }, [user]);

  // useEffect(() => {
  //   setRenderedContentList(renderContent);
  // }, [postsToBeAddedToCollection]);

  const renderContent = () => {
    const contentList = content.map((post) => {
      const isSelected = checkIsSelected(post.post_id);
      return (
        <ContentDisplayBox
          isSelected={isSelected}
          post={post}
          setSelectedPostImage={setSelectedPost}
          onClick={openDrawer}
          isOpen={isOpen}
          onOpen={onOpen}
        />
      );
    });
    return contentList;
  };

  return (
    <Box
      paddingTop="5rem"
      paddingInline="2rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="115vh"
    >
      <HStack
        paddingInline={{ base: "0", sm: "10%" }}
        justifyContent="space-between"
      >
        <HStack>
          <Image width="3.5rem" height="3.5rem" src={TempProfilePhoto} />
          <OpaliteTextSubTitle paddingLeft=".5rem">
            @influencer
          </OpaliteTextSubTitle>
        </HStack>
        <HStack>
          <OpaliteTextHighlighted marginLeft={{ base: "0", md: "-60%" }}>
            {content.length} Selected
          </OpaliteTextHighlighted>
        </HStack>
        <Box display={{ base: "none", md: "block" }} paddingBlock="2rem">
          <Select marginBottom="1rem" color={COLORS_PRIMARY.FONT}>
            <option value="most_liked">Most liked</option>
          </Select>
          <OpaliteButtonStandard
            onClick={() => navigate("/connect")}
            width="100%"
          >
            Batch for Minting
          </OpaliteButtonStandard>
        </Box>
      </HStack>

      <Grid
        justifyContent="center"
        marginTop="2rem"
        templateColumns={{ base: "repeat(3, 4fr)", sm: "repeat(4, 4fr)" }}
        flexDirection="row"
        gap={4}
        height={content.length >= 15 ? "60%" : null}
        overflow="scroll"
        borderColor={COLORS_PRIMARY.BACKGROUND}
      >
        {renderedContentList.length ? renderedContentList : renderContent()}
      </Grid>
      <Box display={{ base: "block", md: "none" }} paddingBlock="2rem">
        <Select marginBottom="1rem" color={COLORS_PRIMARY.FONT}>
          <option value="most_liked">Most liked</option>
        </Select>
        <OpaliteTextStandard marginBottom="1rem">
          Collection Name
        </OpaliteTextStandard>
        <Input
          color={COLORS_PRIMARY.FONT}
          marginBottom="1rem"
          onChange={(e) => setCollectionName(e.target.value)}
        />
        <OpaliteButtonStandard
          onClick={() => {
            updateCollection();
            console.log(postsToBeAddedToCollection);
            // navigate("/connect")
          }}
          width="100%"
        >
          Batch for Minting
        </OpaliteButtonStandard>
      </Box>
      <NFTEditDrawer
        postsToBeAddedToCollection={postsToBeAddedToCollection}
        setPostAddedToCollection={setPostsToBeAddedToCollection}
        post={selectedPost}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default SelectionScreen;
