import { Box, Flex, HStack, Image, Progress, VStack } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import ButtonIcon from "../../images/button_icon.svg";
import { OpaliteTextStandard } from "../shared/opalite_text";
import { useState } from "react";

interface ContentEditBoxProps {
  image: string;
  onOpen: Function;
  onClick: Function;
  post: object;
}

const ContentEditBox: React.FC<ContentEditBoxProps> = (props) => {
  return (
    <Box
      onClick={() => props.onClick()}
      width="20rem"
      height="20rem"
      backgroundImage={props.post.asset_url}
      backgroundRepeat="no-repeat"
      backgroundSize="100% 100%"
      backgroundColor={COLORS_PRIMARY.CONTENT_DISPLAY_BOX_BACKGROUND}
      justifySelf="center"
      zIndex="0"
      padding="1rem"
    >
      <HStack>
        <HStack>
          <Image src={ButtonIcon} />
          <OpaliteTextStandard>{props.post.like_count} </OpaliteTextStandard>
        </HStack>
        <HStack paddingLeft="1rem">
          <Image src={ButtonIcon} />
          <OpaliteTextStandard>{props.post.comment_count} </OpaliteTextStandard>
        </HStack>
        <HStack paddingLeft="1rem">
          <Image src={ButtonIcon} />
          <OpaliteTextStandard>{props.post.share_count} </OpaliteTextStandard>
        </HStack>
        <HStack
          display={props.post.view_count ? "flex" : "none"}
          paddingLeft="1rem"
        >
          <Image src={ButtonIcon} />
          <OpaliteTextStandard>{props.post.view_count} </OpaliteTextStandard>
        </HStack>
      </HStack>
      <Box overflow="scroll" height="15%" position="relative" top="80%">
        <OpaliteTextStandard>
          <b>Caption:</b> {props.post.caption}
        </OpaliteTextStandard>
      </Box>
    </Box>
  );
};

export default ContentEditBox;
